import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Transaction-Library",
  initialState: {
    isFetching: false,
    isDeleteFetching: false,
    isPayingInitial: false,
    isRepaymentStart: false,
    openWaitingModal: false,
    isUpdatingInvoice: false,
    repaymentInitiationStatus: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    stock: null,
    transaction: null,
    selectedTransaction: null,
    carts: null,
    isCartOk: false,
    priceList: null,
    stockByStatus: null,
    recent: null,
    nosale: null,
    onePriceListItem: null,
    checkoutResponseStatus: null,
    customerSignature: null,
    customerProfile: null,
    dataActivated: null,
    paymentInitiationStatus: null,
    paymentDoneStatus: null,
    checkPaymentAgainHelper: null,
    statusResult: null,
    commissionBalance: null,
    shopCommission: null,
    noSaleStats: null,
    freelancerStock: null,
    subscriptionPriceList: null,
    repaymentStatusResult: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsDeleteFetching(state, action) {
      state.isDeleteFetching = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setStock(state, action) {
      state.stock = action.payload;
    },
    setFreelancerStock(state, action) {
      state.freelancerStock = action.payload;
    },
    setStockByStatus(state, action) {
      state.stockByStatus = action.payload;
    },
    setTransation(state, action) {
      state.transaction = action.payload;
    },
    setSelectedTransation(state, action) {
      state.selectedTransaction = action.payload;
    },
    setCarts(state, action) {
      state.carts = action.payload;
    },
    setIsCartok(state, action) {
      state.isCartOk = action.payload;
    },
    setPriceList(state, action) {
      state.priceList = action.payload;
    },
    setRecent(state, action) {
      state.recent = action.payload;
    },
    setNosale(state, action) {
      state.nosale = action.payload;
    },
    setOnePriceListItem(state, action) {
      state.onePriceListItem = action.payload;
    },
    setCheckoutResponseStatus(state, action) {
      state.checkoutResponseStatus = action.payload;
    },
    setCustomerSignature(state, action) {
      state.customerSignature = action.payload;
    },
    setCustomerProfile(state, action) {
      state.customerProfile = action.payload;
    },
    setDataActivated(state, action) {
      state.dataActivated = action.payload;
    },
    setPaymentInitiationStatus(state, action) {
      state.paymentInitiationStatus = action.payload;
    },
    setPaymentDoneStatus(state, action) {
      state.paymentDoneStatus = action.payload;
    },
    setCheckPaymentAgainHelper(state, action) {
      state.checkPaymentAgainHelper = action.payload;
    },
    resetPaymentRelatedStatesService(state) {
      state.paymentInitiationStatus = null;
      state.paymentDoneStatus = null;
      state.checkPaymentAgainHelper = null;
    },
    setStatusResult(state, action) {
      state.statusResult = action.payload;
    },
    setCommisssionBalance(state, action) {
      state.commissionBalance = action.payload;
    },
    setShopCommission(state, action) {
      state.shopCommission = action.payload;
    },
    setNoSaleStats(state, action) {
      state.noSaleStats = action.payload;
    },
    setIsPayingInitial(state, action) {
      state.isPayingInitial = action.payload;
    },
    setSubscriptionPriceList(state, action) {
      state.subscriptionPriceList = action.payload;
    },
    setIsRepaymentStart(state, action) {
      state.isRepaymentStart = action.payload;
    },
    setRepaymentStatusResult(state, action) {
      state.repaymentStatusResult = action.payload;
    },
    setOpenWaitingModal(state, action) {
      state.openWaitingModal = action.payload;
    },
    setIsUpdatingInvoice(state, action) {
      state.isUpdatingInvoice = action.payload;
    },
    setRepaymentInitiationStatus(state, action) {
      state.repaymentInitiationStatus = action.payload;
    },
  },
});

export const myPosActions = mySlice.actions;

export default mySlice.reducer;
