import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoanSummary from "./LoanSummary";
import {
  getCustomerLoanAction,
  getOneCustomerByPhoneAction,
} from "../../../store/customer/actions";
import { useNavigate, useParams } from "react-router-dom";
import { CurstomeCardSkeleton } from "../../../components/skeleton/Card";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { myCustomerActions } from "../../../store/customer";
import { Tooltip } from "antd";
import { myLayoutActions } from "../../../store/layout";

const CustomerLoanDetails = () => {
  const { customer } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerPhone, customerId } = useParams();

  useEffect(() => {
    if (customerId && customerPhone) {
      getOneCustomerByPhoneAction(`?phone=${customerPhone}`)(dispatch);
      getCustomerLoanAction(customerId)(dispatch);
    }
  }, [customerId, customerPhone, dispatch]);

  const handleGoBack = () => {
    dispatch(myCustomerActions.setCustomerByPhone(null));
    dispatch(myCustomerActions.setCustomerLoan(null));
    dispatch(myLayoutActions.setSelectedInvoiceToPay(null));
    navigate("/payment");
  };

  return (
    <div className="w-full h-full md:w-1/2 flex flex-col justify-between bg-white py-6 px-5 text-[#030229] ml-auto mr-auto overflow-y-auto ">
      <div>
        <div className="flex items-center mt[38px] mb-6 gap-x-4">
          <Tooltip title="Back">
            <IoArrowBackCircleOutline
              size={24}
              className="cursor-pointer"
              onClick={handleGoBack}
            />
          </Tooltip>
          <h1 className="text-[20px] font-medium text-center ">
            My Loan Summary
          </h1>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="flex gap-4 border border-[1px]-#03022940 rounded-[6px] py-4 px-4 my-4 w-full md:w-1/2">
            <div className="rounded-full">
              <img
                className="w-24 h-24 md:w-32 md:h-32 object-cover rounded-full"
                src={customer?.customerByPhone?.picture}
                alt="profile"
              />
            </div>
            <div className="text-[16px] font-normal flex flex-col gap-1">
              <p className="text-[18px]">
                {customer?.customerByPhone?.firstName}{" "}
                {customer?.customerByPhone?.lastName}
              </p>
              <p className="text-[#605BFF]">
                {customer?.customerByPhone?.nationalId}
              </p>
              <div className="flex gap-1">
                <p>{customer?.customerByPhone?.email}</p>{" "}
              </div>
              <div className="flex gap-1">
                <p>{customer?.customerByPhone?.phone}</p>{" "}
              </div>
            </div>
          </div>
          {customer?.isGettingCustomerLoan ? (
            <div className="w-full md:w-1/2 mt-1 md:mt-5">
              <CurstomeCardSkeleton />
            </div>
          ) : (
            <div className="border border-[1px]-#03022940 rounded-[10px] py-4 px-6 mt-1 mb-4 md:my-4 font-normal flex flex-col gap-[6px] w-full md:w-1/2">
              <div className="flex items-center gap-x-2 justify-between">
                <p className="text-[15px] opacity-55">Total Amount Paid</p>
                <p className="text-[16px] font-medium">{`${
                  customer?.customerLoan?.totalAmountPaid?.toLocaleString() ||
                  "N/A"
                } RWF`}</p>
              </div>
              <div className="flex items-center gap-x-2 justify-between">
                <p className="text-[15px] opacity-55">Outstanding Balance</p>
                <p className="text-[16px] font-medium">{`${
                  (
                    customer?.customerLoan?.amountToBePaid -
                    customer?.customerLoan?.totalAmountPaid
                  )?.toLocaleString() || "N/A"
                } RWF`}</p>
              </div>
              <div className="flex items-center gap-x-2 justify-between">
                <p className="text-[15px] opacity-55">Total Loan Balance</p>
                <p className="text-[16px] font-medium">{` ${
                  customer?.customerLoan?.amountToBePaid?.toLocaleString() ||
                  "N/A"
                } RWF`}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <LoanSummary />
    </div>
  );
};

export default CustomerLoanDetails;
