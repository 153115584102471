import React, { useEffect, useState } from "react";
import {
  Form,
  RadioChangeEvent,
  notification,
  Table,
  Typography,
  Checkbox,
  Tabs,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllTermsAndConditionsAction } from "../../../store/TermsAndCondirons/actions";
import "./address.css";
import TermsAndConditionContent from "./TermsAndConditionContent";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { LoadingButton } from "@mui/lab";
import { Margin, usePDF } from "react-to-pdf";
import { getOneAccountAction } from "../../../store/account/actions";
import { handleOpenPaymentModelAction } from "../../../apps/device_financing/Subscription/redux/layout/actions";
import { customerDocumentsAction } from "../../../apps/device_financing/Subscription/redux/customer/actions";
import { PiDownloadSimpleFill } from "react-icons/pi";
import HttpRequest from "../../../utils/HttpRequest";
import { SERVER_URL } from "../../../utils/constants";
import ForceLogo from "../../../assets/images/Layer.png";
import { getOneCompanyAction } from "../../../store/company/actions";
import type { TabsProps } from "antd";
import TermsAndConditionKinyarwanda from "./TermsAndConditionKinyarwanda";
import { subCustomerActions } from "../../../apps/device_financing/Subscription/redux/customer";
import { generateFirstInvoiceAction } from "../../../store/pos/actions";
const GeneralTermsAndConditions: React.FC<{
  templateRef: any;
  handlePrint: any;
  props?: any;
  cancel: any;
}> = ({ templateRef, handlePrint, props, cancel }) => {
  const { wareHouse, auth, subCustomer, company, pos } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { cartId, accountId } = useParams();
  const [form] = Form.useForm();
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [downLoadIsLoading, setDownLoadIsLoading] = useState(false);
  const [customerSignature, setCustomerSignature] = useState<any>([]);
  const [acceptTerms, setAcceptTerms] = useState(
    wareHouse?.createdCart?.data?.acceptTermsAndConditions
  );

  const customerData = wareHouse?.createdCart?.data?.customer;

  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const { toPDF, targetRef } = usePDF({
    filename: customerData?.name?.split(" ")?.join("_"),
    page: { margin: Margin.MEDIUM },
  });

  const handleDownloadPDF = async () => {
    toPDF({
      filename: customerData?.name?.split(" ")?.join("_"),
      page: { margin: Margin.MEDIUM },
    });
  };
  const handleClear = () => {
    sign?.clear();
    setCustomerSignature("");
  };

  const handleSignature = async () => {
    const trimmedCanvas = sign?.getTrimmedCanvas();
    if (trimmedCanvas) {
      const dataUrl = trimmedCanvas.toDataURL("image/png");
      if (dataUrl) {
        const res = await customerDocumentsAction(
          auth?.token,
          {
            image: dataUrl,
          },
          "signature"
        )(dispatch);
        if (res?.type) {
          await updateShopStockCartDetailsAction(auth?.token, cartId, {
            customerSignature: res?.docLink,
            acceptTermsAndConditions: true,
          })(dispatch);
          await generateFirstInvoiceAction(
            auth?.token,
            cartId as string
          )(dispatch);
        }
      }
    }
  };

  const openNotification = () => {
    notification.open({
      message: "Customer signature is required",
      description:
        "please provide Customer signature to accept Terms and conditions",
    });
  };

  const checkSignature = async () => {
    if (sign?.isEmpty()) {
      openNotification();
    } else {
      await handleSignature();
      await handleDownloadPDF();
      await cancel();
      await handleOpenPaymentModelAction(true)(dispatch);
    }
  };

  const onFinish = async (values: any) => {
    if (customerSignature) {
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        customerSignature: customerSignature,
        acceptTermsAndConditions: true,
      })(dispatch);
      // cancel();
    } else {
      notification.info({ message: "Please Upload Customer Signature" });
    }
  };

  const onActionChange = async (e: RadioChangeEvent) => {
    setAcceptTerms(e.target.checked);
  };

  const handleDownlodTermsAndConditions = async () => {
    setDownLoadIsLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/cart/contract/${cartId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setDownLoadIsLoading(false);
      if (!response.ok) throw new Error("Failed to fetch the contract");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;

      a.download = `${customerData?.name}_${cartId}.pdf`;
      document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownLoadIsLoading(false);
      console.error("Error downloading the contract:", error);
    }
  };

  const { Text } = Typography;

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
    },
    {
      title: "IMEI",
      dataIndex: "sirialNumber",
    },
    {
      title: "Installment Plan",
      dataIndex: "installmentPlan",
      render: (text: any, record: any) => (
        <p className="cursor-pointer">
          <span className="text-xs">{`${text} Months`}</span>
        </p>
      ),
    },
    {
      title: "Installment type",
      dataIndex: "installmentType",
      render: (text: any, record: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Recurring Payment",
      dataIndex: "recurringPayment",
    },
  ];
  const customerColumns = [
    {
      title: "Buyer's Name",
      dataIndex: "data",
      render: (text: any) => {
        console.log("data to display", text);
        return <></>;
      },
    },
    {
      title: "Id Number",
      dataIndex: "NId",
    },
    {
      title: "Product Serial Number",
      dataIndex: "SerialNumber",
    },
  ];
  React.useEffect(() => {
    getAllTermsAndConditionsAction(auth.token, "?")(dispatch);
  }, [dispatch, auth.token]);

  const priceListId =
    wareHouse?.createdCart?.data?.list &&
    wareHouse?.createdCart?.data?.list[0]?.priceProduct?.subscriptionPlan[0]
      ?.pricelistItem;

  const dataToDisplay = wareHouse?.createdCart?.data?.list?.map((el: any) => {
    return {
      key: el._id,
      brand: el?.warehouseProduct?.product?.brand,
      model: el?.warehouseProduct?.product?.model,
      price: el?.payment?.amount?.toLocaleString(),
      sirialNumber: el?.warehouseProduct?.serialNumber,
      recurringPayment: el?.payment?.installementPayment?.toLocaleString(),
      capacity: el?.shopStockItem?.requestedItem?.product?.specification
        .filter((item: any) => item[0] === "Capacity (ROM)")
        .map((item: any) => item[1]),
      installmentType: wareHouse?.createdCart?.data?.instalmentPlan,
      installmentPlan: wareHouse?.createdCart?.data?.subscriptionPlan,
      priceListId: priceListId,
      data: el,
    };
  });
  const today = new Date().toISOString().split("T")[0];
  const dataCustomer = wareHouse?.createdCart?.data?.customer;
  const customerToDisplay = Array.isArray(dataCustomer)
    ? dataCustomer
    : [dataCustomer];
  const amountToPay =
    wareHouse?.createdCart?.data?.list &&
    wareHouse?.createdCart?.data?.list[0]?.payment?.installementPayment;

  useEffect(() => {
    getOneAccountAction(accountId as string, auth.token)(dispatch);
    dispatch(subCustomerActions.setIsFetching(false));
  }, [accountId, auth.token, dispatch]);
  const companyId =
    process.env.COMPANY_PROFILE_ID || "671808faf6e98f39bccb48a9";
  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
  }, [dispatch]);

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    setSelectedLanguage(value);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  return (
    <>
      {/* <button onClick={handleDownloadPDF}>Download PDF</button> */}
      <div className="flex justify-end items-end mb-10 md:mt-5">
        <Select
          showSearch
          placeholder="Select Language"
          defaultValue={"english"}
          optionFilterProp="label"
          onChange={onChange}
          onSearch={onSearch}
          options={[
            {
              value: "english",
              label: "English",
            },
            {
              value: "kinyarwanda",
              label: "Kinyarwanda",
            },
          ]}
        />
      </div>
      <div ref={targetRef}>
        <div className="flex flex-col justify-center items-center">
          <img
            src={ForceLogo}
            className="cursor-pointer duration-500 w-[10rem]  mt-2"
            alt=""
          />
        </div>

        <div className="h-[80vh] overflow-y-auto px-2 mt-5">
          {selectedLanguage === "english" ? (
            <div>
              <h1 className="text-[#0F0F47] texte-base sm:text-lg font-bold capitalize text-center underline">
                Instalment Sale Agreement Terms and Conditions
              </h1>
              <p className="text-[#030229] text-sm sm:text-lg pt-5">
                These terms and conditions of sale ("Agreement") are entered
                into, by and between {company?.selected?.name} Digital Retailers
                Ltd. with tax reference number{" "}
                {wareHouse?.createdCart?.data?.cartId}, trading as{" "}
                {company?.selected?.name} (the "Seller") and the "Buyer":
              </p>
              {/* <Table
                columns={customerColumns}
                dataSource={customerToDisplay}
                pagination={false}
                bordered
              /> */}
              <div className="grid px-4 md:grid-cols-3 gap-5 border-solid border-2 md:border-4 text-[#030229] text-sm md:text-base border-black mt-5">
                <div className="grid grid-cols-2 gap-5 px-2">
                  <p>Buyer's Name </p>
                  <p className=" md:border-x-2 border-black">
                    {customerData?.name}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <p>ID Number </p>
                  <p className="md:border-x-2 border-black">
                    {customerData?.nationalId}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <p>Product Serial Number</p>{" "}
                  <p className="md:border-x-2 border-black">
                    {
                      wareHouse?.createdCart?.data?.list[0]?.warehouseProduct
                        ?.serialNumber
                    }
                  </p>
                </div>
              </div>
              {/* <div className="sm:flex justify-between  mt-5">
            <div className="mr-3 rounded-md mt-12">
              <img
                src={
                  customerData?.subscriptionInfo?.photoCustomerHoldingID ||
                  customerData?.picture
                }
                alt="profile"
                width={200}
                className="rounded-md"
              />
            </div>
          </div> */}
              <div className="">
                {/* <h1 className="text-[#0F0F47] text-lg font-bold capitalize py-10 ">
              Purchased Devices
            </h1>
            <div className="w-full overflow-auto">
              <Table
                columns={columns}
                dataSource={dataToDisplay}
                pagination={false}
                bordered
                summary={(pageData) => {
                  let totalPrice = pageData && pageData[0]?.price;
                  pageData.forEach((item: any) => {
                    const p = item?.p;

                    if (p && p.payment && p.payment.amount) {
                      totalPrice += p.payment.amount;
                    }
                  });

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          index={0}
                          colSpan={5}
                          className="font-bold"
                        >
                          Total Installment Payment Amount
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={3}>
                          <Text className="text-[18px] font-bold">
                            {totalPrice} Rfw
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </div> */}
                {/* {termsAndCondions?.all?.data ? ( */}
                <div className="divides-y dividde-black ">
                  <h1 className="text-base sm:text-[22px] text-center  font-bold uppercase p-s10"></h1>
                  <section className="text-sm sm:text-base ">
                    <div className="mt-5">
                      <h1 className="text-[#030229]] text-sm  my-2">
                        It is manutually aggreed:
                      </h1>
                      <ol
                        className="list-decimal"
                        type="A"
                        style={{ listStyle: "display" }}
                      >
                        <li className="mt-3">
                          <b>Sale of Product.</b>
                          <span className="text-sm">
                            Subject to formal credit approval from the Seller,
                            the Buyer accepts the “Product” provided by the
                            Seller and acknowledges that he/she has taken
                            delivery of the Product from the Seller and entered
                            into an Instalment Sale Agreement.
                          </span>
                        </li>
                        <li>
                          <b>Commitment to Pay Purchase Price.</b>
                          <span className="text-sm">
                            Buyer has elected to pay for the Product by means of
                            an Instalment Sale Agreement and therefore commits
                            to pay the Purchase Price rather than the Cash
                            Price. The Buyer <u>commits</u> to pay:
                          </span>
                          <ol
                            type="A"
                            style={{ listStyle: "display", marginLeft: "20px" }}
                          >
                            <li className="mt-3">
                              An Initial payment prior to acceptance of the
                              Product (the “Deposit”); and
                            </li>
                            <li className="mt-3">
                              The Minimum Instalments as each one becomes due
                              (each “Instalment Period”), until the Purchase
                              Price has been fully paid.
                            </li>
                          </ol>
                        </li>
                        <li className="mt-3">
                          <b>Payment.</b>
                          <span className="text-sm">
                            The Buyer acknowledges that payment of the Purchase
                            Price to the Seller will be by means of mobile money
                            transfer exclusively. No other form of payment will
                            be accepted. The Seller will not be held responsible
                            for any loss or damage caused by the buyer making
                            payment to an individual or company through means
                            other than that stated below.
                          </span>
                        </li>
                        <li className="mt-3">
                          <b>Default to Pay.</b>
                          <span className="text-sm">
                            If the Buyer fails to make the minimum required
                            instalments in each Instalment Period, per this
                            Agreement:
                          </span>
                          <ol
                            type="A"
                            style={{ listStyle: "display" }}
                            className="ml-10"
                          >
                            <li>
                              <b>Device Deactivation:</b>
                              <span className="text-sm">
                                The Seller reserves the right to deactivate the
                                Product until the Buyer has paid all of the
                                monies owed for past and current Instalment
                                Periods;
                              </span>
                            </li>
                            <li>
                              <b>Backpay:</b>
                              <span className="text-sm">
                                each day missed will result in a phone use days
                                lost, eg. if the Buyer misses a payment for 2
                                days, and makes a payment worth 7 days, they
                                will only be awarded 5 days when they pay;
                              </span>
                            </li>
                            <li>
                              <b>Device Return:</b>
                              <span className="text-sm">
                                if the Buyer is more than 14 days overdue on his
                                / her instalments (“Long Default”), the Buyer
                                commits to return the product to the Seller in a
                                condition satisfactory to the Seller and in the
                                original packaging within 3 days. The Buyer will
                                not be refunded for prior payments made. The
                                Buyer will remain obliged to pay the Purchase
                                Price; and
                              </span>
                            </li>
                            <li>
                              <b>Police / Village Leader Involvement:</b>
                              <span className="text-sm">
                                if the Buyer is late in paying and has not
                                returned the device in satisfactory condition
                                within the specified period, the Seller will use
                                the services of village leaders and the police
                                to enforce the terms of this contract.
                              </span>
                            </li>
                            <li>
                              <b>CRB:</b>
                              <span className="text-sm">
                                CRB: if the buyer fails to pay the device, the
                                Seller reserve to right to immediate report the
                                Buyer in the credit reference bureau (CRB) with
                                the exact amount due.
                              </span>
                            </li>
                          </ol>
                        </li>
                        <li className="mt-3">
                          <b>Title.</b>
                          <span className="text-sm">
                            Title over the Product will transfer to the Buyer on
                            the date of delivery. However, Samphone Rwanda
                            remains entitled to the physical return of the
                            Product and thereafter the return of the title of
                            the Product from the Buyer should the Buyer go into
                            Long Default.
                          </span>
                        </li>
                        <li className="mt-3">
                          <b>Theft/ Damage / Loss.</b>
                          <span className="text-sm">
                            In the event that the Product is stolen, damaged or
                            lost, the Buyer remains committed to pay the full
                            Purchase Price and all Minimum Instalments within
                            the Payment Period. In the event of theft, the Buyer
                            commits to report the matter to police authorities
                            within two days so that the Product may be traced.
                          </span>
                        </li>
                        <li className="mt-3">
                          <b>Tampering / Flashing / Unlocking.</b>
                          <span className="text-sm">
                            The Buyer undertakes to make no efforts to unlock
                            the device by means of flashing, tampering, part
                            replacement or other. If the Buyer attempts to
                            unlock or flash the Product through any means other
                            than payment, <u>criminal charges</u> will be
                            brought against the Buyer. The Buyer commits to
                            protect the Product from damage or harm.
                          </span>
                        </li>
                        <li className="mt-3">
                          <b>Sale Not Permitted.</b>
                          <span className="text-sm">
                            The Buyer may not sell the device until the entire
                            Purchase Price has been paid. If the Buyer attempts
                            to sell the device before the Purchase Price has
                            been paid, <u>criminal charges</u> will be brought
                            against the Buyer.
                          </span>
                        </li>
                        <li className="mt-3">
                          <b>Warranty.</b>
                          <span className="text-sm">
                            For a period of 12 months (“Warranty Period”)
                            following the signature of this Agreement, the
                            Seller will repair or replace the Product through a
                            repair and maintenance Service Centre subject to the
                            following:
                          </span>
                          <ol
                            type="A"
                            style={{ listStyle: "display" }}
                            className="ml-10"
                          >
                            <li className="pt-3">
                              The Buyer notifies the Seller within the Warranty
                              Period of a product defect;
                            </li>
                            <li className="pt-3">
                              At the time of notification, the Buyer has paid
                              the minimum instalment amounts as per this
                              Agreement; and
                            </li>
                            <li className="pt-3">
                              The product defect is a factory fault and is not a
                              result of Product use contrary to the intended
                              use, nor the result of negligence or accident of
                              any kind.
                            </li>
                          </ol>
                        </li>
                        <li className="mt-3">
                          <b>VAT.</b>
                          <span className="text-sm">
                            The Seller provides the sale of its product to
                            customers in the form of an “Instalment Sale
                            Agreement”, the goods or services are considered to
                            be successively supplied for successive parts of the
                            period of the agreement and each successive supply
                            occurs on the earlier of the date on which payment
                            is due or received.
                          </span>
                        </li>
                        <li className="mt-3">
                          <b>Governing Law.</b>
                          <span className="text-sm">
                            This agreement is governed by the laws of the
                            Republic of Rwanda.
                          </span>
                        </li>
                      </ol>
                      <div className="grid md:grid-cols-2">
                        <span className="py-2">Today's Date : {today}</span>
                        <span>Signature of Buyer </span>
                      </div>
                      <div className="grid md:grid-cols-3">
                        <span>Merchant Name: {company?.selected?.name} </span>
                        <span className="py-3">Signature of Merchant </span>
                        <span>
                          Merchant Contact Number: {company?.selected?.phone}
                        </span>
                      </div>
                    </div>

                    {/* <TermsAndConditionContent /> */}
                  </section>
                </div>

                <div>
                  <h1 className="text-base sm:text-lg font-bold capitalize py-5 ">
                    {selectedLanguage === "english"
                      ? "Copy of National Id"
                      : "Fotocopy y'irengamuntu"}
                  </h1>
                  <div className="rounded-md">
                    <img
                      src={customerData?.subscriptionInfo?.nationalIDDoc}
                      alt="National Id"
                      className="object-cover w-[320px] h-32 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <TermsAndConditionKinyarwanda />
            </div>
          )}
          <div className="mt-8 ml-3">
            <Form
              form={form}
              name="register"
              layout="vertical"
              onFinish={onFinish}
              initialValues={{}}
              style={{ maxWidth: "100%" }}
              scrollToFirstError
              className=""
            >
              {!wareHouse?.createdCart?.data?.acceptTermsAndConditions && (
                <Checkbox onChange={onActionChange}>
                  {" "}
                  {selectedLanguage === "english" ? (
                    <p>I Agree with terms and conditions </p>
                  ) : (
                    <p>Ndemeranya n'ayamategeko n'amabwiriza</p>
                  )}
                </Checkbox>
              )}
            </Form>
            {(acceptTerms ||
              wareHouse?.createdCart?.data?.acceptTermsAndConditions) && (
              <div className="min-h-52 w-[22rem] ">
                <p className="text-[#0F0F47] pt-8 text-sm sm:text-center font-normal drawText">
                  {selectedLanguage === "english"
                    ? "Draw your Signature"
                    : "Sinya hano"}
                </p>
                <SignatureCanvas
                  canvasProps={{
                    className:
                      "mt-4 h-[153px] w-[250px] sm:w-[352px] border-dotted border-2 border-indigo-300 px-3 py-2 cursor-pointer",
                  }}
                  ref={(data: any) => setSign(data)}
                  penColor="black"
                />
                <LoadingButton
                  variant="contained"
                  loading={downLoadIsLoading}
                  sx={{
                    // backgroundColor: "rgb(229 231 235)",
                    // color: "black",
                    padding: "5px 25px",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                  onClick={handleDownlodTermsAndConditions}
                  className="px-2 py-1 rounded-md bg-gray-200 m-2 "
                >
                  <div className="flex items-center justify-center gap-2 ">
                    <PiDownloadSimpleFill color="white" size={20} /> Terms &
                    Condition
                  </div>
                </LoadingButton>
                <div className=" sm:float-right mt-4 printBtn ">
                  <LoadingButton
                    sx={{
                      backgroundColor: "rgb(229 231 235)",
                      color: "black",
                      padding: "5px 25px",
                      marginRight: "10px",
                    }}
                    onClick={handleClear}
                    className="px-8 py-1 rounded-md bg-gray-200 m-2"
                  >
                    Clear
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    sx={{
                      padding: "5px 25px",
                    }}
                    onClick={() => {
                      checkSignature();
                    }}
                    disabled={
                      wareHouse?.isReFetchingCart ||
                      subCustomer?.isFetching ||
                      pos?.isFetching
                    }
                    loading={
                      wareHouse?.isReFetchingCart ||
                      subCustomer?.isFetching ||
                      pos?.isFetching ||
                      wareHouse?.isUpdateFetching
                    }
                  >
                    Save
                  </LoadingButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralTermsAndConditions;
