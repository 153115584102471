import { Tag } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchValue } from "../../../utils/setColor";
import { CurstomeCardSkeleton } from "../../../components/skeleton/Card";
import CustomModal from "../../../components/Modals/CustomModal";
import {
  handleOpenRepaymentModel,
  handleSelectInvoiceToRepay,
} from "../../../store/layout/actions";
import ConfirmRepayment from "./ConfirmRepayment";
import MomoWaitingAndSuccess from "./MomoWaitingAndSuccess";

const LoanSummary = () => {
  const { customer, layout, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    handleOpenRepaymentModel(false)(dispatch);
    handleSelectInvoiceToRepay(null, 0)(dispatch);
  };

  const handlerSelectInvoice = (data: any) => {
    handleSelectInvoiceToRepay(data, data?.amountToBePaid)(dispatch);
    handleOpenRepaymentModel(true)(dispatch);
  };

  useEffect(() => {
    if (layout?.selectedInvoiceToPay === null) {
      handleOpenRepaymentModel(false)(dispatch);
    }
  }, [dispatch, layout?.selectedInvoiceToPay]);

  return (
    <>
      <div className="w-full h-full py-2 px-5 text-[#030229] font-normal ml-auto mr-auto overflow-y-auto">
        <div>
          <div className="flex flex-col gap-2">
            {customer?.isGettingCustomerLoan ? (
              <div className="mt-3">
                <CurstomeCardSkeleton />
              </div>
            ) : (
              <>
                {customer?.customerLoan?.length > 0 && (
                  <h1 className="text-[16px] font-medium text-start pt-3">
                    Select invoice to pay
                  </h1>
                )}
                <div className="grid md:grid-cols-2 gap-2">
                  {customer?.customerLoan?.invoices?.map(
                    (data: any, index: number) => (
                      <div
                        className={`border-l-4 border-[#2aba3e] w-full  rounded-[10px] px-4 py-4 flex flex-col gap-2 my-2 relative bg-white shadow`}
                        key={index}
                      >
                        <p className="text-[18px] text-[#575757]">{`${data?.account?.accountID}`}</p>
                        <p className="text-[20px] font-medium">
                          {`${data?.amountToBePaid?.toLocaleString()} RWF`}
                        </p>
                        <p className="text-[16px] text-[#595959]">
                          {`Due on: ${data?.dueDate?.slice(0, 10) || ""}`}
                        </p>

                        <div className="absolute right-3 top-3  text-white text-sm w-[87px] h-[32px] rounded-[6px] flex justify-center items-center">
                          <Tag
                            color={searchValue(data?.status)}
                            style={{ minWidth: "5rem", textAlign: "center" }}
                            className="py-1"
                          >
                            <span className="text-[#030229B2] text-sm capitalize py-2">
                              {" "}
                              {data?.status}
                            </span>
                          </Tag>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {customer?.customerLoan?.invoices?.length > 0 &&
          !customer?.isGettingCustomerLoan &&
          !layout?.openRepaymentModel &&
          !pos?.openWaitingModal && (
            <div className="flex mx-auto items-center justify-center pt-6">
              <button
                className="bg-[#605BFF] w-full md:w-1/2 text-[14px] px-10 py-2 rounded-[5px] text-white"
                onClick={() =>
                  handlerSelectInvoice(customer?.customerLoan?.invoices[0])
                }
              >
                PAY NOW
              </button>
            </div>
          )}
      </div>
      <CustomModal
        openModal={layout?.openRepaymentModel}
        cancelModal={handleCloseModal}
        width={500}
        component={<ConfirmRepayment />}
      />
      <CustomModal
        openModal={pos?.openWaitingModal}
        // cancelModal={() => handleOpenRepaymentModel(false)(dispatch)}
        width={500}
        component={<MomoWaitingAndSuccess />}
      />
    </>
  );
};
export default LoanSummary;
